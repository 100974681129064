@media (min-width: 0px) {
  .small-video {
    width: 50vw;
    height: 50vw;
    margin-left: 2vw;
  }
  .row-container {
    display: flex;
    align-content: center;
  }
  .mobile-container {
    display: block;
    justify-content: center;
    margin-left: 40px;
    margin-top: 1vw;
    margin-bottom: 10px;
  }
  .video-container {
    width: 2vw;
  }
  .pc-container {
    margin-left: 40px;
    display: block;
    align-items: center;
    align-content: center;
    height: 39vw;
    width: 50vw;
  }
  .unclicked-crop-image {
    width: 50%;
    display: flex;
    height: 33%;
    justify-content: center;
    overflow: hidden;
    margin: 0.5vw;
  }
  .clicked-crop-image {
    width: 50%;
    height: 33%;
    display: flex;
    overflow: hidden;
    justify-content: center;
    border: rgb(252, 231, 98), 1vw, solid;
    margin-right: 0.5vw;
  }
  .lg-mobile-image {
    height: 50.5vw;
    width: 25vw;
  }
  .sm-mobile-image {
    height: 20vw;
    width: 30vw;
  }
  .sm-pc-image {
    height: 15.5vw;
    width: 20vw;
  }
  .lg-pc-image {
    width: 55vw;
    height: 35vw;
    margin-left: 20px;
    margin-top: 3%;
  }
  .whole-container {
    display: block;
  }
}

@media (min-width: 750px) {
  .small-video {
    width: 30vw;
    height: 20vw;
    margin-left: -13.5vw;
  }
  .row-container {
    display: block;
  }
  .mobile-container {
    display: flex;
    width: 30vw;
    height: 30vw;
    justify-content: center;
    margin-top: 1vw;
  }
  .pc-container {
    display: flex;
    width: 30vw;
    height: 20vw;
    justify-content: center;
    margin-top: -1vw;
  }
  .unclicked-crop-image {
    width: 10vw;
    display: flex;
    height: 10vw;
    justify-content: center;
    overflow: hidden;
    margin: 0.5vw;
  }
  .clicked-crop-image {
    width: 10vw;
    height: 10vw;
    display: flex;
    overflow: hidden;
    justify-content: center;
    border: rgb(89, 197, 170), 0.3vw, solid;
    margin: 0.5vw;
  }
  .lg-mobile-image {
    height: 27.5vw;
    width: 12vw;
  }
  .sm-mobile-image {
    height: 20vw;
    width: 15vw;
  }
  .sm-pc-image {
    height: 10.5vw;
    width: 15vw;
  }
  .lg-pc-image {
    margin-top: 5.5vw;
    width: 30vw;
    height: 18vw;
    margin-right: 2vw;
  }
  .whole-container {
    display: block;
  }
  .video-container {
    margin-bottom: -5vw;
  }
}
@media (min-width: 2000px) {
  .small-video {
    width: 30vw;
    height: 20vw;
    margin-left: -13.5vw;
  }
  .small-video {
    margin-top: -6.5vw;
    height: 20vw;
  }
  .row-container {
    display: block;
  }
  .mobile-container {
    display: flex;
    width: 25vw;
    height: 33vw;
    justify-content: center;
    margin-top: -2vw;
  }
  .pc-container {
    display: flex;
    width: 30vw;
    height: 20vw;
    justify-content: center;
    margin-top: -1vw;
  }
  .unclicked-crop-image {
    width: 8vw;
    display: flex;
    height: 8vw;
    justify-content: center;
    overflow: hidden;
    margin: 0.5vw;
    margin-top: -8vw;
  }
  .clicked-crop-image {
    width: 8vw;
    height: 8vw;
    display: flex;
    overflow: hidden;
    justify-content: center;
    border: rgb(89, 197, 170), 0.3vw, solid;
    margin: 0.5vw;
    margin-top: -8vw;
  }
  .lg-mobile-image {
    height: 22.5vw;
    width: 10vw;
  }
  .sm-mobile-image {
    height: 15vw;
    width: 10vw;
  }
  .sm-pc-image {
    height: 10.5vw;
    width: 15vw;
  }
  .lg-pc-image {
    margin-top: -5.5vw;
    margin-bottom: -5vw;
    width: 25vw;
    height: 15vw;
    margin-right: 2vw;
  }
  .whole-container {
    display: block;
  }
}
