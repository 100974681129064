.entire-page {
  animation: fade-out 0.5s ease-in forwards;
}
.aboutme-container,
.contactme-container {
  color: white;
  text-align: center;
  font-weight: 600;
  align-content: center;
}
.contactme-container {
  width: 85vw;
  height: 100%;
  display: flex;
  justify-content: center;
}
.reachme-container {
  color: white;
  text-align: center;
  margin-right: 5vw;
  font-size: 1vw;
  font-weight: 700;
}
.contact-link {
  color: white;
}
.images-body {
  display: flex;
  font-size: 0.8vw;
  justify-content: center;
}
.me-txt {
  width: 40vw;
}
.contactme-container {
  width: 85vw;
  align-self: center;
}
.contact-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5vw 0;
}
.slideshow-container,
.contactme-container {
  background-color: rgba(0, 0, 0, 0.429);
  backdrop-filter: blur(3px);
  align-content: center;
  border: white, solid, 0.3vw;
  border-radius: 1vw;
}

.contactme-container {
  color: white;
  text-align: center;
  font-weight: 600;
  margin: 0 auto;
  padding: 1vw;
}

.slideshow-container {
  padding: 1vw;
  margin-right: 1vw;
}
.slideshow-description-container {
  display: flex;
  justify-content: center;
}
.contact-header-txt,
.contact-txt {
  flex: 1;
  text-align: center;
  margin: 0;
  padding: 0.2vw;
  color: white;
  font-weight: 700;
}
.contact-txt-email {
  color: white;
  font-weight: 700;
}
@media (min-width: 0px) {
  .contactme-container {
    display: block;
  }
  .contact-link {
    font-size: 3vw;
  }
  .slideshow-description-container {
    display: block;
  }
  .homedescription-container {
    margin-top: 5vw;
    margin-left: 10vw;
    width: 80vw;
    height: 35vw;
  }
  .slideshow-container {
    height: 40vw;
    width: 50vw;
    margin-left: 25vw;
  }
  .reachme-container {
    font-size: 3.5vw;
    text-align: center;
    margin-left: 5svw;
  }
}
@media (min-width: 750px) {
  .contactme-container {
    display: flex;
    height: 10vw;
  }
  .slideshow-description-container {
    display: flex;
  }
  .slideshow-container {
    width: 0vw;
    margin-left: 0vw;
  }
  .homedescription-container {
    width: 50vw;
    margin-top: 2vw;
    height: 19vw;
    margin-left: 0vw;
  }
  .contact-txt-email {
    font-size: 2.2vw;
  }
  .contact-header-txt,
  .contact-txt {
    font-size: 2vw;
  }
  .slideshow-container {
    width: 30vw;
    height: 22vw;
  }
  .reachme-container {
    font-size: 1.5vw;
    margin-top: -1vw;
    margin-left: 0vw;
  }
  .contact-link {
    font-size: 1.6vw;
  }
}
@media (min-width: 1800px) {
  .homedescription-container {
    width: 50vw;
    height: 15vw;
  }
  .contact-txt-email {
    font-size: 1.8vw;
  }
  .contact-header-txt,
  .contact-txt {
    font-size: 1.5vw;
  }
  .contact-txt {
    margin-left: 2vw;
  }
  .slideshow-container {
    width: 30vw;
    height: 13vw;
  }
  .contactme-container {
    width: 85vw;
    height: 6vw;
  }
  .reachme-container {
    font-size: 1vw;
  }
  .contact-link {
    font-size: 1vw;
  }
  .homedescription-container {
    margin-top: 0vw;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
