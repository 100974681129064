.clapper {
  position: absolute;
  animation: clapperAnimation 2s ease-in-out;
  transform-origin: 10vw;
}
.base {
  position: absolute;
  animation: baseAnimation 2s infinite ease-in-out;
}
.loaded-base {
  position: absolute;
  animation: baseDone 1s ease-in;
}
.loaded-clapper {
  position: absolute;
  transform-origin: 10vw;
  animation: clapperDone 1s ease-in;
}
.parent-container {
  position: relative;
  overflow: hidden;
  height: 90vh;
}

@media (min-width: 0px) {
  .base,
  .loaded-base {
    margin-left: -10vw;
    width: 120vw;
    margin-top: 15vw;
  }
  .clapper,
  .loaded-clapper {
    width: 120vw;
    margin-left: -11vw;
    margin-top: 15vw;
  }
}
@media (min-width: 750px) {
  .base,
  .loaded-base {
    margin-left: 25vw;
    width: 55vw;
    margin-top: 9.5vw;
  }

  .clapper,
  .loaded-clapper {
    width: 55vw;
    margin-left: 25vw;
    margin-top: 10vw;
  }
}
@media (min-width: 1100px) {
  .base,
  .loaded-base {
    margin-left: 25vw;
    width: 40vw;
    margin-top: 9vw;
  }
  .clapper,
  .loaded-clapper {
    width: 40vw;
    margin-left: 25vw;
    margin-top: 10.5vw;
  }
}
@media (min-width: 2000px) {
  .base,
  .loaded-base {
    margin-left: 35vw;
    width: 25vw;
    margin-top: 0vw;
  }
  .clapper,
  .loaded-clapper {
    width: 25vw;
    margin-left: 35vw;
    margin-top: 0.5vw;
  }
}

@keyframes clapperAnimation {
  0% {
    transform: translateY(0.8vw) translateX(-1.8vw) rotate(13deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
@keyframes clapperDone {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: translateY(0.8vw) translateX(-1.8vw) rotate(13deg);
  }
  100% {
    transform: translateY(200%);
  }
}
@keyframes baseDone {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(200%) rotate(180deg);
  }
}
@keyframes baseAnimation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.2vw);
  }
}
