.row {
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
}
.image-row {
  height: 20vw;
  width: 47vw;
  overflow-x: hidden;
  display: flex;
  scroll-behavior: smooth;
  margin-left: 1vw;
}
.image {
  height: 15vw;
  width: 15vw;
}
.arrow-btn {
  background-color: transparent;
  border: none;
  color: white;
  width: 5%;
  height: 75%;
  cursor: pointer;
}
.roll-txt {
  font-weight: 900;
  width: 8vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform: translateX(7.5vw) translateY(-7.5vw) rotate(90deg);
}
.clicked,
.unclicked {
  align-content: center;
}
.clicked {
  height: 15vw;
  width: 15vw;
  border: rgb(89, 197, 170), solid, 0.5vw;
}
.unclicked {
  width: 15vw;
  height: 15vw;
}
@media (min-width: 0px) {
  .image,
  .clicked,
  .unclicked {
    width: 24.5vw;
    height: 24.5vw;
  }
  .arrow-btn {
    width: 12%;
    margin-top: -4vw;
  }
  .image-row {
    width: 77vw;
    height: 30vw;
  }
  .roll-txt {
    font-size: 3.5vw;
    width: 12vw;
    transform: translateX(13vw) translateY(-14vw) rotate(90deg);
  }
}
@media (min-width: 750px) {
  .image,
  .clicked,
  .unclicked {
    width: 15vw;
    height: 15vw;
  }
  .image-row {
    height: 16vw;
    width: 47vw;
  }
  .arrow-btn {
    margin-top: 0vw;
  }

  .roll-txt {
    font-size: 2vw;
    width: 8vw;
    transform: translateX(7vw) translateY(-8vw) rotate(90deg);
  }
}
@media (min-width: 1100px) {
  .image,
  .clicked,
  .unclicked {
    width: 10vw;
    height: 10vw;
  }
  .arrow-btn {
    width: 6%;
  }
  .roll-txt {
    width: 5vw;
    font-size: 1vw;
    transform: translateX(5vw) translateY(-5vw) rotate(90deg);
  }
  .image-row {
    width: 31vw;
    height: 12vw;
  }
}
@media (min-width: 1800px) {
  .roll-txt {
    width: 4vw;
    font-size: 0.8vw;
    transform: translateX(3.3vw) translateY(-3.5vw) rotate(90deg);
  }
  .image,
  .clicked,
  .unclicked {
    width: 7vw;
    height: 7vw;
  }
  .clicked {
    border: rgb(89, 197, 170), solid, 0.3vw;
  }
  .image-row {
    width: 22vw;
    height: 8vw;
  }
}
