.title-container,
.title-container-construction .skill-container,
.list-container,
.skill-container {
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.338);
}

.git-link {
  color: white;
}

.content-container {
  display: block;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  margin-left: 3vw;
}
.images-container {
  justify-content: center;
  width: 100%;
  display: flex;
  height: 60vw;
}
.content-info-container {
  display: block;
}
.skill-container {
  width: 90vw;
  height: 20.5vw;
  border: solid, white, 1vw;
  margin: 10px;
  align-content: center;
  text-align: center;
  border-radius: 20px;
}
.title-container,
.title-container-construction {
  width: 90vw;
  height: 10vw;
  border: solid, white, 1vw;
  margin: 10px;
  border-radius: 20px;
  color: white;
  font-weight: 600;
  font-size: 5vw;
  text-align: center;
  align-content: center;
}
.list-container {
  height: 30vw;
  border-radius: 20px;
  width: 90vw;
  border: solid, white, 1vw;
  text-align: center;
  overflow-y: auto;
  margin-left: 10px;
}
.description-container {
  width: 90vw;
  height: 50vw;
  color: white;
  font-weight: 500;
  font-size: 2.5vw;
  text-align: center;
  align-content: center;
  margin: 10px;
}
.icon {
  align-self: center;
  border-radius: 2vw;
  height: 15%;
  width: 15%;
  padding: 1vw;
}
.project-button {
  border: none;
  background-color: transparent;
  color: white;
  font-size: 8vw;
  font-weight: 1000;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}
.project-button:hover {
  color: lightgrey;
}

@media (min-width: 0px) {
  .quad-container {
    height: 10vw;
  }
  .description-container {
    margin-bottom: 20px;
    height: 35vw;
  }
  .images-container {
    margin-top: 10vw;
    height: 55vw;
  }
  .project-button {
    font-size: 6.5vw;
    margin-bottom: 2vw;
    margin-top: 2vw;
  }
  .skill-container {
    height: 12vw;
  }
  .icon {
    width: 7.5%;
    margin-right: 2vw;
    margin-left: 2vw;
  }
}

@media (min-width: 750px) {
  .content-container {
    display: flex;
    justify-content: center;
  }
  .images-container {
    height: 46vw;
    margin-top: 0vw;
    margin-bottom: -3vw;
  }
  .quad-container {
    height: 10vw;
  }
  .skill-container {
    width: 55vw;
    height: 12.5vw;
    border: solid, white, 0.3vw;
    margin: 10px;
    border-radius: 20px;
  }
  .title-container,
  .title-container-construction {
    width: 55vw;
    height: 3vw;
    margin: 10px;
    border-radius: 20px;
    border: solid, white, 0.3vw;
    font-weight: 600;
    font-size: 2vw;
  }
  .list-container {
    height: 46.5vw;
    border-radius: 20px;
    border: solid, white, 0.3vw;
    width: 35vw;
  }
  .description-container {
    width: 55vw;
    height: 23vw;
    font-size: 1.2vw;
  }

  .project-button {
    font-size: 3vw;
    font-weight: 1000;
    margin-bottom: 50px;
  }
  .icon {
    height: 15%;
    width: 15%;
    margin-right: 0vw;
    margin-left: 0vw;
  }
}

@media (min-width: 1150px) {
  .images-container {
    margin-left: 0;
    height: 0vw;
    margin-bottom: 0vw;
  }
  .quad-container {
    height: 0vw;
  }
  .content-info-container {
    display: flex;
    position: fixed;
  }
  .skill-container {
    border: solid, white, 0.3vw;
    width: 35vw;
    height: 10vw;
    margin: 10px;
    align-content: center;
    text-align: center;
    border-radius: 20px;
  }
  .title-container,
  .title-container-construction {
    border: solid, white, 0.3vw;
    width: 35vw;
    height: 3vw;
    margin: 10px;
    border-radius: 20px;
    font-weight: 600;
    font-size: 2vw;
  }
  .list-container {
    border: solid, white, 0.3vw;
    height: 43.5vw;
    border-radius: 20px;
    width: 25vw;
  }
  .description-container {
    width: 35vw;
    height: 23vw;
    font-weight: 600;
    font-size: 1vw;
  }
  .icon {
    width: 12%;
  }
  .project-button {
    font-size: 3vw;
    font-weight: 1000;
    margin-bottom: 50px;
  }
}
@media (min-width: 2000px) {
  .description-container {
    height: 15vw;
    font-size: 0.8vw;
  }
  .content-container {
    margin-top: -0vw;
  }
  .list-container {
    margin-top: 0vw;
    height: 30.7vw;
  }
  .project-button {
    font-size: 2vw;
  }
  .images-container {
    margin-top: 2.5vw;
    width: 30vw;
  }
  .skill-container {
    height: 7vw;
  }
}
