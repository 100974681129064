.crop-image {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.img-row-container {
  display: flex;
  width: 30vw;
  overflow: hidden;
}
.images-row {
  display: flex;
  gap: 1vw;
}
.floating-description {
  position: fixed;
  z-index: 1;
  color: white;
  align-content: center;
  text-align: center;
  font-weight: 800;
  backdrop-filter: blur(10px);
  border-radius: 1vw;
  min-height: 4vw;
  border: white, solid, 0.1vw;
  pointer-events: none;
}
@media (min-width: 0px) {
  .floating-description {
    transform: translate(-200px, -200px);
    font-size: 2.5vw;
    width: 15vw;
  }
  .img-row-container {
    width: 40vw;
  }
  .images-row {
    animation: cyclethroughmobile 15s infinite linear;
  }
  .crop-image {
    width: 40vw;
    height: 40vw;
  }
  .portrait-image-element {
    width: 40vw;
  }
  .horizontal-image-element {
    width: 90vw;
  }
}
@media (min-width: 750px) {
  .floating-description {
    transform: translate(-150px, -150px);
    font-size: 1vw;
    width: 8vw;
  }
  .img-row-container {
    width: 30vw;
  }
  .images-row {
    animation: cyclethroughnormal 15s infinite linear;
  }
  .crop-image {
    height: 22vw;
    width: 25vw;
  }
  .portrait-image-element {
    width: 25vw;
  }
  .horizontal-image-element {
    width: 50vw;
  }
}
@media (min-width: 1800px) {
  .floating-description {
    transform: translate(-230px, -230px);
    font-size: 0.8vw;
    width: 7vw;
  }
  .crop-image {
    height: 12vw;
    width: 12vw;
  }
  .portrait-image-element {
    width: 15vw;
  }
  .horizontal-image-element {
    width: 25vw;
  }
  .images-row {
    animation: cyclethroughwide infinite 20s linear;
  }
}

@keyframes cyclethroughnormal {
  0% {
    transform: translateX(-20vw);
  }
  100% {
    transform: translateX(-124vw);
  }
}
@keyframes cyclethroughwide {
  0% {
    transform: translateX(30vw);
  }
  100% {
    transform: translateX(-85vw);
  }
}
@keyframes cyclethroughmobile {
  0% {
    transform: translateX(-20vw);
  }
  100% {
    transform: translateX(-184vw);
  }
}
