.entire-audio-container {
  border: white, 0.3vw, solid;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  color: white;
}
.desc-container {
  height: 80%;
  width: 100%;
  overflow-y: auto;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: rgba(0, 0, 0, 0.331);
}
.playbackbar-container {
  height: 20%;
  width: 100%;
  display: flex;
  font-size: 100%;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgba(0, 0, 0, 0.484);
}
.desc-container,
.playbackbar-container {
  justify-content: center;
  align-items: center;
  text-align: center;
  backdrop-filter: blur(5px);
}
.playbackinfo-container {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 0px;
  transform: translateY(-18px);
}
.playback-txt {
  font-size: 4vw;
  margin-right: 2vw;
  margin-left: 2vw;
}
.media-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: 100%;
}

@media (min-width: 0px) {
  .entire-audio-container {
    border: white, solid, 1vw;
  }

  .description-txt {
    font-size: 2.7vw;
    padding: 2vw;
    margin-top: -1vw;
    width: calc(98% - 3vw);
  }
}
@media (min-width: 750px) {
  .description-txt {
    width: 95%;
    padding: 0.7vw;
    font-size: 2vw;
    font-weight: 600;
    margin-top: 0vw;
  }

  .playback-txt {
    font-size: 2.3vw;
  }
  .description-txt {
    font-size: 1.2vw;
  }
  .entire-audio-container {
    border: white, solid, 0.3vw;
  }
}

@media (min-width: 1800px) {
  .description-txt {
    padding: 0vw;
    font-size: 1vw;
    font-weight: 600;
  }

  .playback-txt {
    font-size: 2vw;
  }
}
