html,
body {
  margin: 0;
  padding: 0;
  background-image: linear-gradient(#1781ff, #000);
  background-color: #1781ff;
  -webkit-overflow-scrolling: touch;
}

body {
  background-image: url("background.webp");
  background-attachment: scroll;
  background-size: cover;
}

.pages-container {
  width: 100%;
  height: 100%;
}
.logo-img {
  cursor: pointer;
}
.appbar {
  display: flex;
  align-items: center;
  width: 100%;
  backdrop-filter: blur(100px);
  border-top: none;
  z-index: 10;
  min-height: 50px;
}
.app-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
}
.app-btn {
  background-color: transparent;
  color: white;
  cursor: pointer;
  border: none;
  font-weight: 800;
  margin-right: 2vw;
  margin-left: 2vw;
}
.logo-text,
.logo-text-hover {
  width: 0vw;
  text-wrap: nowrap;
  align-content: center;
}

.social-img {
  padding: 0.5vw;
  text-align: center;
}

@media (min-width: 0px) {
  .appbar {
    margin-bottom: -5vh;
  }
  .homeheader-text {
    font-size: min(4vw, 35px);
  }
  .pages-container {
    padding-top: 10vw;
  }
  html,
  body {
    height: 105%;
  }
  .social-img {
    width: 7vw;
    height: 7vw;
    margin-top: 2.8vw;
  }
  .logo-img {
    height: 15vw;
    width: 15vw;
  }
  body {
    background-size: 300vw;
  }
  .app-btn {
    font-size: 3.5vw;
  }
  .logo-text,
  .logo-text-hover {
    font-size: 2vw;
    color: transparent;
  }
}
@media (min-width: 750px) {
  .logo-text-hover {
    animation: nameFadein 0.5s ease-in;
    color: white;
  }
  .logo-text {
    animation: nameFadeout 0.5s ease-in;
    color: transparent;
  }
  .logo-text-hover {
    color: white;
  }
  .homeheader-text {
    font-size: min(2vw, 35px);
  }
  .pages-container {
    padding-top: 5vw;
  }
  .appbar {
    height: 4vw;
    margin-bottom: min(-4vw, -30px);
  }

  html,
  body {
    height: 100%;
  }
  .social-img {
    margin-top: 0.3vw;
    width: 2.5vw;
    height: 2.5vw;
  }
  .app-btn {
    font-size: min(2vw, 25px);
  }
  .logo-img {
    height: 4vw;
    width: 4vw;
  }
  body {
    background-size: 170vw;
  }
  .logo-text,
  .logo-text-hover {
    font-size: 1.5vw;
  }
}
@media (min-width: 1300px) {
}
@media (min-width: 2400px) {
  .appbar {
    margin-bottom: -12vh;
  }
}
@keyframes nameFadein {
  0% {
    color: rgba(255, 255, 255, 0);
  }
  100% {
    color: white;
  }
}
@keyframes nameFadeout {
  0% {
    color: white;
  }
  100% {
    color: rgba(255, 255, 255, 0);
  }
}
