.images,
.software {
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.342);
  border-radius: 20px;
}
.cube-btn {
  width: 5vw;
  height: 5vw;
  background-color: transparent;
  border: none;
}
.cube-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cube {
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(-10deg) rotateY(0deg);
}
.cube-loading {
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(-10deg) rotateY(0deg);
  animation: loading 2s infinite ease-in-out;
}

.cube-right {
  text-align: center;
  width: 100%;
  animation: rotateRight 0.7s ease-in-out;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(-10deg) rotateY(0deg);
}

.cube-left {
  text-align: center;
  width: 100%;
  animation: rotateLeft 0.7s ease-in-out;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(-10deg) rotateY(0deg);
}
.cube-right-full {
  text-align: center;
  width: 100%;
  animation: rotateRightFull 0.7s ease-in-out;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(-10deg) rotateY(0deg);
}

.cube-left-full {
  width: 100%;
  animation: rotateLeftFull 0.7s ease-in-out;
  text-align: center;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(-10deg) rotateY(0deg);
}

.face {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(128, 128, 128);
  border: 1px solid rgb(0, 0, 0);
}

.name-txt {
  color: white;
  font-weight: 600;
}

.photos-container {
  display: flex;
  background-color: grey;
  width: 10vw;
  height: 10vw;
}
.bottom-row {
  display: flex;
  justify-content: center;
  margin-top: 5vw;
}
.description {
  width: 30vw;
  text-align: center;
  align-content: center;
  height: 20vw;
  font-weight: 500;
  color: white;
  font-size: 1.5vw;
}
.offset-images {
  margin-top: -1vw;
  width: 30vw;
}
.images {
  height: 35vw;
  width: 35vw;
  margin-left: 0vw;
  align-content: center;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
}
.software {
  height: 20vw;
  width: 20vw;
  margin-left: 6.5vw;
  text-align: center;
  align-content: center;
}
.software-icon {
  width: 10vw;
}
@media (min-width: 0px) {
  .cube-row {
    margin-left: -5vw;
  }
  .entire-container {
    margin-top: 15vw;
  }
  .bottom-row {
    display: block;
  }
  .name-txt {
    font-size: 5vw;
  }
  .button-offset {
    width: 5vw;
  }
  .cube-btn {
    font-size: 10vw;
    margin: 10vw;
    width: 12vw;
    height: 12vw;
  }
  .cube-container {
    width: 35vw;
    height: 35vw;
    margin-bottom: 10vw;
  }
  .front {
    align-content: center;
    transform: rotateY(0deg) translateZ(17.5vw);
  }

  .back {
    align-content: center;
    transform: rotateY(180deg) translateZ(17.5vw);
  }

  .left {
    align-content: center;
    transform: rotateY(-90deg) translateZ(17vw);
  }

  .right {
    align-content: center;
    transform: rotateY(90deg) translateZ(17vw);
  }

  .top {
    transform: rotateX(90deg) translateZ(17.5vw);
  }

  .bottom {
    transform: rotateX(-90deg) translateZ(17.5vw);
  }
  .images {
    margin-left: 5vw;
    margin-top: 5vw;
    border: solid, white, 1vw;
    width: 90vw;
    height: 50vw;
  }
  .offset-images {
    margin-top: 0vw;
    margin-left: 0vw;
  }
  .software {
    width: 25vw;
    height: 15vw;
    width: 90vw;
    border: solid, white, 1vw;
    margin-top: 6vw;
    margin-left: 4.5vw;
  }
  .description {
    height: 30vw;
    width: 90vw;
    font-size: 3vw;
    margin-left: 4.5vw;
  }
}
@media (min-width: 750px) {
  .cube-row {
    margin-left: 0vw;
  }
  .bottom-row {
    display: flex;
  }
  .entire-container {
    margin-top: 0;
  }
  .name-txt {
    font-size: 3.5vw;
  }
  .images {
    margin-left: 0vw;
    margin-top: 0vw;
    width: 37vw;
    border: white, solid, 0.3vw;
    height: 37vw;
  }
  .button-offset {
    width: 0vw;
  }
  .cube-btn {
    height: 7vw;
    width: 7vw;
  }
  .cube-container {
    perspective: 100vw;
    margin-bottom: 0;
    width: 20vw;
    height: 20vw;
  }
  .front {
    align-content: center;
    transform: rotateY(0deg) translateZ(10vw);
  }

  .back {
    align-content: center;
    transform: rotateY(180deg) translateZ(10vw);
  }

  .left {
    align-content: center;
    transform: rotateY(-90deg) translateZ(10vw);
  }

  .right {
    align-content: center;
    transform: rotateY(90deg) translateZ(10vw);
  }

  .top {
    transform: rotateX(90deg) translateZ(10vw);
  }

  .bottom {
    transform: rotateX(-90deg) translateZ(10vw);
  }
  .cube-row {
    margin-right: 0vw;
    width: 100vw;
  }
  .offset-images {
    margin-top: 1vw;
    margin-left: 29vw;
  }
  .software {
    margin-top: 0vw;
    width: 35vw;
    border: white, solid, 0.3vw;
    height: 15vw;
  }
  .description {
    width: 35vw;
    height: 15vw;
    font-size: 1.2vw;
    margin-left: 0;
  }
}
@media (min-width: 1150px) {
  .cube-btn {
    height: 5vw;
    width: 5vw;
  }

  .entire-container {
    display: flex;
    justify-content: center;
    margin-left: -10vw;
    margin-top: 2vw;
  }
  .offset-images {
    margin-left: -5vw;
  }
  .cube-row {
    width: 70vw;
  }
  .software {
    width: 20vw;
    height: 15vw;
  }
  .description {
    width: 30vw;
    height: 15vw;
    font-size: 1.2vw;
  }
  .images {
    border: white, solid, 0.4vw;
    width: 34vw;
  }
}
@media (min-width: 2000px) {
  .cube-btn {
    height: 5vw;
    width: 5vw;
  }
  .cube-container {
    perspective: 100vw;
    margin-bottom: 0;
    width: 15vw;
    height: 15vw;
  }
  .front {
    align-content: center;
    transform: rotateY(0deg) translateZ(7.5vw);
  }

  .back {
    align-content: center;
    transform: rotateY(180deg) translateZ(7.5vw);
  }

  .left {
    align-content: center;
    transform: rotateY(-90deg) translateZ(7.5vw);
  }

  .right {
    align-content: center;
    transform: rotateY(90deg) translateZ(7.5vw);
  }

  .top {
    transform: rotateX(90deg) translateZ(7.5vw);
  }

  .bottom {
    transform: rotateX(-90deg) translateZ(7.5vw);
  }
  .entire-container {
    display: flex;
    justify-content: center;
    margin-left: -10vw;
    margin-top: 5vw;
    overflow-y: none;
  }
  .offset-images {
    margin-left: -5vw;
    overflow-y: hidden;
  }
  .cube-row {
    height: 0vw;
    width: 70vw;
    margin-bottom: 10vw;
    margin-top: 5vw;
  }
  .software {
    border: white, solid, 0.4vw;
    width: 15vw;
    height: 11vw;
  }
  .description {
    width: 25vw;
    height: 11vw;
    font-size: 0.8vw;
  }
  .images {
    margin-top: -5vw;
    height: 30vw;
    position: absolute;
    overflow-y: hidden;
    border: white, solid, 0.4vw;
  }
  .name-txt {
    font-size: 2.5vw;
  }
  .software-icon {
    width: 6vw;
  }
}

@keyframes loading {
  0% {
    transform: rotateX(-10deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(-10deg) rotateY(360deg);
  }
}

@keyframes rotateLeft {
  0% {
    transform: rotateX(-10deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(-10deg) rotateY(90deg);
  }
}

@keyframes rotateRight {
  0% {
    transform: rotateX(-10deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(-10deg) rotateY(-90deg);
  }
}
@keyframes rotateRightFull {
  0% {
    transform: rotateX(-10deg) rotateY(0deg);
  }
  50% {
    transform: rotateX(-10deg) rotateY(-45deg);
  }
  100% {
    transform: rotateX(-10deg) rotateY(0deg);
  }
}
@keyframes rotateLeftFull {
  0% {
    transform: rotateX(-10deg) rotateY(0deg);
  }
  50% {
    transform: rotateX(-10deg) rotateY(45deg);
  }
  100% {
    transform: rotateX(-10deg) rotateY(0deg);
  }
}
