.film-date,
.software-container,
.film-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.311);
  border: solid, white, 0.25vw;
  backdrop-filter: blur(2px);
  padding: 2vw;
  border-radius: 2vw;
  margin: 0.5vw;
  text-align: center;
  align-content: center;
}
.film-description {
  margin: 0.5vw;
  padding: 2vw;
}
.film-page {
  overflow: auto;
  height: 100%;
}
.film-row {
  display: flex;
  width: 100vw;
}
.content-row {
  display: flex;
  width: 100vw;
  justify-content: center;
}
.film-container {
  margin: 0.5vw;
  margin-left: 1.5vw;
  align-content: center;
  text-align: center;
}
.information-container {
  display: block;
  margin-right: 2vw;
}
.date-software-container {
  height: 10vw;
  display: flex;
  margin-left: 2vw;
}
.software-img {
  width: 100%;
  height: max(5vw, 40px);
  width: max(5vw, 40px);
  border-radius: 2vw;
  padding: 1vw;
  margin-top: 5px;
}
.film-description {
  font-weight: 500;
}
.film-date {
  font-weight: 700;
  width: calc(23vw - 2vw);
}
.software-container {
  width: calc(18vw - 2vw);
  border-radius: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.film-title {
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: min(5vw, 30px);
}
.bottom-container {
  align-content: center;
  text-align: center;
}
@media (min-width: 0px) {
  .film-page {
    width: 100%;
    height: 100%;
  }
  .film-title,
  .film-description {
    width: 91vw;
  }
  .content-row {
    margin-top: 10vw;
    display: block;
  }
  .film-container {
    min-width: 95vw;
    height: 50vw;
    margin-bottom: 12vw;
    margin-right: 12vw;
  }
  .film-description {
    height: 45vw;
    margin-left: 2vw;
    font-size: 3vw;
    width: 91vw;
    padding: 1vw;
    margin-bottom: 2vw;
    margin-top: 0vw;
  }
  .software-container,
  .film-date {
    width: 48vw;
    height: 10vw;
    border: white, solid, 1vw;
  }

  .bottom-container {
    height: 50vw;
  }
}
@media (min-width: 750px) {
  .content-row {
    margin-top: 0vw;
    display: flex;
  }
  .software-container,
  .film-date {
    font-size: 2vw;
    height: 10vw;
    width: 18.5vw;
    border: white, solid, 0.3vw;
  }

  .film-title {
    height: 2vh;
    width: 35vw;
    font-size: 2vw;
  }
  .film-description {
    margin-top: 0vw;
    padding: 1vw;
    font-size: 1.6vw;
    width: 46vw;
    margin-left: 2vw;
    height: calc(30vw - 2vw);
  }
  .bottom-container {
    margin-top: 0vw;
    height: 25vh;
  }
  .film-container {
    min-width: 40vw;
    margin-right: 0vw;
    height: 30vw;
  }
  .information-container {
    height: 50vw;
  }
}
@media (min-width: 1100px) {
  .film-page {
    height: 100%;
  }
  .information-container {
    height: 0vw;
  }
  .software-container,
  .film-date {
    font-size: 2.5vw;
    height: 5vw;
  }
  .film-container {
    min-width: 40vw;
    height: 20vw;
  }
  .film-title {
    height: 2vw;
    font-size: 2vw;
  }
  .film-description {
    padding: 1vw;
    font-size: 1.3vw;
    width: 46vw;
    margin-left: 2vw;
    height: calc(20vw - 2vw);
  }
  .bottom-container {
    height: 12vw;
  }
}
@media (min-width: 1800px) {
  .film-description,
  .film-date,
  .software-container,
  .film-title {
    padding: 1vw;
  }
  .content-row {
    margin-top: 2vw;
  }
  .software-container,
  .film-date {
    font-size: 1.3vw;
    height: 2vw;
    margin-top: 0vw;
  }

  .film-container {
    min-width: 40vw;
    height: 19vw;
  }
  .film-title {
    font-size: 1vw;
    height: 1vw;
  }
  .film-description {
    font-size: 1vw;
    width: calc(45vw - 1vw);
    margin-left: 2vw;
    height: calc(18vw - 1vw);
    width: 42vw;
    margin-bottom: 0vw;
  }
  .bottom-container {
    margin-top: -7vw;
    height: 8vw;
  }
  .film-page {
    margin-top: -2vw;
    height: 100%;
  }
}
